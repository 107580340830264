@import "../../scss/vars.scss";

.label-check {
    display: none;
  }
  
  .hamburger-label {
    width: 70px;
    height: 58px;
    display: block;
    cursor: pointer;
  }
  
  .hamburger-label div {
    width: 70px;
    height: 6px;
    position: absolute;
  }
  
  .line1 {
    transition: all .3s;
  }
  
  .line2 {
    margin: 18px 0 0 0;
    transition: 0.3s;
  }
  
  .line3 {
    margin: 36px 0 0 0;
    transition: 0.3s;
  }
  
  #label-check:checked + .hamburger-label .line1 {
    transform: rotate(35deg) scaleX(.55) translate(39px, -4.5px);
    border-radius: 50px 50px 50px 0;
  }
  
  #label-check:checked + .hamburger-label .line3 {
    transform: rotate(-35deg) scaleX(.55) translate(39px, 4.5px);
    border-radius: 0 50px 50px 50px;
  }
  
  #label-check:checked + .hamburger-label .line2 {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 45px;
  }

// ! mobile
.hamburger{
transform: rotate(180deg) scale(0.55);
  position: absolute;
  bottom: 5px;
  left: 10px;
}