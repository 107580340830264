.label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #394a56;
  }
  
  .label-text {
    margin-left: 16px;
  }
  
  .toggle {
    isolation: isolate;
    position: relative;
    height: 30px;
    width: 60px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: -8px -4px 8px 0px #ffffff,
      8px 4px 12px 0px #d1d9e6,
      4px 4px 4px 0px #d1d9e6 inset,
      -4px -4px 4px 0px #ffffff inset;
      &:hover{
        opacity: 0.8;
      }
  }
  
  .toggle-state {
    display: none;
  }
  
  .indicator {
    height: 100%;
    width: 200%;
    background: #ecf0f3;
    border-radius: 15px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
    box-shadow: -8px -4px 8px 0px #ffffff,
      8px 4px 12px 0px #d1d9e6;
  }
  
  .toggle-state:checked ~ .indicator {
    transform: translate3d(25%, 0, 0);
  }

  // ! dark theme
.dark{
  .toggle{
    background: #000;
    box-shadow:none;
  }
  .indicator{
    background: #222;
    box-shadow:none;
  }
}