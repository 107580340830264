// ! scrollbar
body::-webkit-scrollbar {
	width: 10px;
}
// other scrollbars
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-thumb {
	background: lighten(#222, 10%);
	border-radius: 20px;
}
::-webkit-scrollbar-track {
	background: $white;
	border-radius: 20px;
}
// ? scrollbar