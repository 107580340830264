@import "../../scss/vars.scss";

// ! SimpleMDE
.CodeMirror{
	border-radius: $borderRadiusSmall !important;
	margin: 0 auto;
}

// ! dark theme mode
.dark,.darkDialog{
	.CodeMirror{
		background: $dark200;
		color: $white;
	}
}

// ! change the minimum height (before it starts auto-growing):
.CodeMirror, .CodeMirror-scroll {
    min-height: 30px !important;
}
// !! hide some not important editor options
.editor-statusbar{
	display: none;
}
.editor-toolbar{
	display: none !important; // ! hide all tools without showTextEditorOptions in TextEditor className
	.separator, .preview, .side-by-side, .fullscreen, .image {
		display: none !important;
	}
}

.showTextEditorOptions{
	.editor-toolbar{ // ! show all tools with showTextEditorOptions in TextEditor className
		display: block !important;
	}
}

.editor-toolbar{
	button{
		&:hover{
			background: $black;
		}
		background: transparent !important;
	}
}
// ? SimpleMDE

// !! fix all <pre> tags text
pre{
	white-space: pre-line;
	hyphens: auto;
	font-family: $fontMain;
	// !! force margin inside <pre> tags
	p,h1,h2,h3,h4,h5,h6,img {
		margin-bottom: 15px;
	}
	}