@import "../../scss/vars.scss";

.banner{
    display: flex;
    align-items: center;
    justify-content: center;
    &__imgWrap{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    &__img{
        width: 100%;
        @media (width<=457px){
            width: 90%;
            margin-left: 15px;
           }
    }
    &__textp1{
        font-size: 34px;
        margin-bottom: 15px;
        letter-spacing: 1px;
        line-height: 55px;
        @media (width<=$mobile){
            font-size: 28px;
        }
    }
    &__textp2{
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 30px;
    }
    // ! `mobile`
    @media (width<=$tablet){
        height: auto;
        margin-bottom: 120px;
        margin-top: 88px; // ???
        padding-top: 25px;
        &__text{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap !important;
            margin-top:0px !important;
        }
        &__textp1{
        }
        &__textp1,&__textp2{
            max-width: 430px;
            padding: 15px;
        }
        flex-direction: column;
        &__imgWrap{
            flex-direction: column;
        }
        &__arrow{
           transform: rotate(-90deg);
           margin-right: 45px;
           max-width: 220px !important;
           @media (width<=457px){
            margin-top: -50px !important;
            max-width: 150px !important;
           }
        }
        &__arrow,&__text{
           margin-top: -100px;
        }
        &__imgLeftTitle{
            // works for 360p
            // top: -179px;
            // left: -320px;
            // font-size: 11px;
            display: none;
        }
    }
    // ! pc
    @media (width>$tablet){
        &__textp1{
            width: 400px;
        }
        &__textp2{
            width: 415px;
        }
    }
}

// ! banner2
.banner2AllText{
    order: 1;
}
.banner2MainImg{
    order: 2;
}

@media (width<=$tablet){
    .banner2AllText{
        order: 2 !important;
        text-align: center;
        padding: 30px;
        margin-right: 0;
    }
    .banner2MainImg{
        order: 1 !important;
        img{
            width: 90%;
            margin-left: 30px;
        }
    }
    .banner2{
        height: auto;
    }
}

// ! special media
@media (width<=1316px){
    .banner__arrow{
        display: none;
    }
}