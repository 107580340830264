// ! WIDTH & HEIGHT
.wS{ // width Small
	max-width: $wS;
}
.wM{ // width Medium
	max-width: $wM;
}
.wL{ // width Large
	max-width: $wL;
}
.innerImgW100p{
    img{ // !!
        width: 100%;
    }
}
//
.w100vw{
	width: 100vw;
}
.w100p{
	width: 100%;
}
.w90p{
	width: 90%;
}
.w80p{
	width: 80%;
}
.w70p{
	width: 70%;
}
.w60p{
	width: 60%;
}
.w50p{
	width: 50%;
}
.wfc{
	width: fit-content;
}
.maw100p{
	max-width: 100%;
}
.miw100vw{
	min-width: 100vw;
}
// gen min-width
@for $i from 0 through 500 {
	.miw#{$i} {
	  min-width: #{$i}px;
	}
 }
 // gen max-width
@for $i from 0 through 1000 {
	.maw#{$i} {
	  max-width: #{$i}px;
	}
 }
// gen width
@for $i from 0 through 1700 {
	.w#{$i} {
	  width: #{$i}px;
	}
 }