@import "../../scss/vars.scss";

// Dialog: unbind mui's setting, so I can set any width and height
[role="dialog"]{ // !!!
    max-width: 100vw !important;
    max-height: 100vh !important;
	min-width: 330px !important;
	min-height: 200px !important;
	background: rgba(255, 255, 255, 0.8) !important;
	backdrop-filter: blur(8px) !important;
	.MuiDialogContent-root{ // hide x-axis scroll
		overflow-x: hidden !important;
	}
}

// mui dark dialog
.darkDialog{
	.MuiDialog-container.MuiDialog-scrollPaper{
		.MuiPaper-root{
			background: rgba(40, 44, 52, 0.8) !important; // $dark300 with alpha chanel 0.8
		}
	}
	* {
		color: $white !important;
	}
	input{
		color: $white;
	}
}

.dialogCloseIcon,.dialogBackIcon{
	@media (width<=$mobile) {
		position: relative;
		top: 0px !important;
	}
}