@import "../../scss/vars.scss";

$myMsgColor: #bdffe9;
$otherMsgColor: #bde9ff;

.rooms{
    a{  // !! make rooms width 100%
        width: 100%;
    }
}

.myMsg{
    background: $myMsgColor;
    // right shadow
    box-shadow:  -10px 10px 21px #9dd4c1,
             10px -10px 21px #ddffff;
}

.otherMsg{
    background: $otherMsgColor;
    // left shadow
    box-shadow:  10px 10px 21px #9dc1d4,
                 -10px -10px 21px #ddffff;
}

.updatedMsg{
    box-shadow: -10px 0px 10px -5px lightgreen !important;
}

.deletedMsg{
    background: #e0e0e0 !important;
    box-shadow:  10px 10px 20px #bebebe,
                 -10px -10px 20px #ffffff !important;
}

.restoredMsg{
    box-shadow: -7px 0px 10px -5px lighten(orange, 20%) !important;
}

.replyMsg{
    border-left: 5px solid #87CEEB;
    padding-left: 10px;
    background: transparent !important;
    box-shadow: -10px 0px 10px -5px #87CEEB;
    margin-right: auto;
    // second msg inside replyMsg
    .myMsg{
        box-shadow:none;
    }
}

.msg{
    max-width: 500px;
    hyphens: auto;
    white-space: pre-line;
    word-break: break-all;
    @media (width<=$mobile) { 
        max-width: 280px; // ???        
    }
}

// ! dark theme mode
.dark{
    .myMsg,.otherMsg,.updatedMsg,.restoredMsg,.replyMsg,.msg{
        box-shadow:none;
        *:not(svg,.brand,.brandi){
            // color: $black !important;
        }
    }
    .deletedMsg{
        box-shadow:none !important;
    }
    .myMsg{
        background: darken($myMsgColor, 50%);
    }
    .otherMsg{
        background: darken($otherMsgColor, 50%);
    }
}