@import "../../scss/vars";

.radio-container {
    display: inline-block;
    position: relative;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    width: 50%;
    padding: 0 30px;
    white-space: nowrap;
  }

  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

.coach{
  margin-left: auto;
  pointer-events: none;
}

.applier{
  margin-right: auto;
}

.course{
  overflow: hidden; // ! for animation: courseLabelAnimationFade<In/Out>
  height: 520px;
  width: 300px;
  margin: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  border-radius: $borderRadiusSmall;
  &:hover{
    box-shadow: inset 0px 10px 15px -3px rgba(0,0,0,0.1);
  }
}

// ! mobile
.coaching{ // coaching has 2 cards
  padding: 15px;
  .radio-container,.coachCardRatingTitle{ // TRAVELING < STAYING AT HOME, TECH INNOVATIONS: does not fit into screen
    font-size: 13px;
  }
  .coach{ // coach card
    margin-bottom: 15px;
  }
  @media (width <=$mobile) {
  }
}