// ! BACKGROUND & COLOR
.brand{
	color: $brand;
	fill: $brand;
}
.brandi{
	color: $brand !important;
	fill: $brand !important;
}
.danger{
	color: $danger !important;
}
.danger2{
	color: $danger2 !important;
}
.info{
	color: $info !important;
}
.orange{
	color: $orange !important;
}
.white{
	color: $white;
}
.black{
	color: $black;
}
.gray{
	color: $gray;
}
.redDiamond{
	color: #FC3952;
}
// ! light theme mode
.bg{
	&_gray{
		background: #dadada;
	}
	&_green{
		background: #85b9b2;
	}
	&_white{
		background: $white;
	}
	&_black{
		background: $black;
	}
	&_brand{
		background: $brand;
	}
	&_danger{
		background: lighten($danger, 25%) !important;
	}
	&_danger2{
		background: lighten($danger2, 25%) !important;
	}
	&_orange{
		background: $orange !important;
	}
	&_app{
		background: $appBg;
	}
	&_transparent{
		background: transparent;	
	}
}
// ! dark theme mode
.dark{
	.bg{
		&_gray{
			background: #b8b8b8;
		}
	}
}
.border{
	&_danger{
		border: 1px solid $danger !important;
	}
	&_danger2{
		border: 1px solid $danger2 !important;
	}
	&_info{
		border: 1px solid $info !important;
	}
	&_orange{
		border: 1px solid $orange !important;
	}
	&_black{
		border: 2px solid $black;
	}
}