// ! BORDERS
.brL{
	border-radius: $borderRadiusLarge;
}
.brM{
	border-radius: $borderRadiusMedium;
}
.brS{
	border-radius: $borderRadiusSmall;
}
.br50{
	border-radius: 50%;
}
.bn{
	border: none !important;
}
.on{
	outline: none !important;
}
// gen border-top-right-radius
@for $i from 0 through 30 {
	.btrr#{$i} {
		border-top-right-radius: #{$i}px;
	}
 }
// gen border-top-left-radius
@for $i from 0 through 30 {
	.btlr#{$i} {
		border-top-left-radius: #{$i}px;
	}
 }
 // gen border-bottom-right-radius
@for $i from 0 through 30 {
	.bbrr#{$i} {
		border-bottom-right-radius: #{$i}px;
	}
 }
 // gen border-bottom-left-radius
@for $i from 0 through 30 {
	.bblr#{$i} {
		border-bottom-left-radius: #{$i}px;
	}
 }
// gen border-radius
@for $i from 0 through 30 {
	.br#{$i} {
		border-radius: #{$i}px;
	}
 }
// gen border color
@for $i from 0 through 10 {
	.bs#{$i}_g {
		border: #{$i}px solid gray;
	}
	.bs#{$i}_lg {
		border: #{$i}px solid lightgray;
	}
	.bs#{$i}_b {
		border: #{$i}px solid black;
	}
	.bs#{$i}_brand {
		border: #{$i}px solid $brand;
	}
 }
// COMMENTED TO SAVE MEMORY
// //  gen border right
// @for $i from 0 through 30 {
// 	.brr#{$i} {
// 		border-right: #{$i}px solid $brand;
// 	}
//  }
// //  gen border bottom
// @for $i from 0 through 30 {
// 	.brb#{$i} {
// 		border-bottom: #{$i}px solid $brand;
// 	}
//  }

// // gen border left
// @for $i from 0 through 30 {
// 	.brl#{$i} {
// 		border-left: #{$i}px solid $brand;
// 	}
//  }

// // gen border top
// @for $i from 0 through 30 {
// 	.brt#{$i} {
// 		border-top: #{$i}px solid $brand;
// 	}
//  }