@import "../../scss/vars.scss";

.addMessageFiles{
    @media (width<=$mobile) {
    }
    @media (width>$mobile) {
    }
}

.messages{
    @media (width<=$mobile) {
        padding-right: 15px;
    }
}

.uploadedFilePreview{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed $gray;
    border-radius: $borderRadiusLarge;
    margin: 5px;
    padding: 10px;
    width: 200px;
    height: 200px;
    word-break: break-all;
    overflow: hidden;
    object-fit: cover;
    cursor: pointer;
}