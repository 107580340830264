@import "vars.scss";

// ! main dark theme mode
.dark,.darkDialog{
    *:not(svg,.brand,.brandi,.redDiamond,.gray){
        color: $white;
        .gray{
            color: $grayDarkMode !important;
        }
        .brand,.brandi{
            color: $brand !important;
        }
     }
     svg:not(.brand,.brandi){
        fill: $white !important; // ???
     }
.bg_white{
    background: $dark300 !important;
}
.bg_white.zi1{
    background: $dark300 !important;
}
.bg_white.zi2{
    background: $dark200 !important;
}
.bg_white.zi3{
    background: $dark100 !important;
}
// !
.bg_black{
    background: $white !important;
}
}

// for App
.dark.bg_app{
    background: $dark300 !important;
}