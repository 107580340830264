@import "../../scss/vars";

.footer{
    @media (width<=$mobile) {
        width: fit-content;
        flex-direction: column;
        gap: 15px;
        left: 50%; // only centers this way
        transform: translate(-50%);
    }
}

// right side of footer
.footerLinks div{
    @media (width<=$mobile) {
        flex-direction: column !important;
        text-align: center;
    }
}