@import "../../scss/vars.scss";

.toolCard{ // posts when clicked 1 of 4 "tools"
    overflow: hidden;
    background: $white;
    img{
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
    @media (width <=$mobile){
        width: 330px;
    }
}

.toolCardsHiddenTitles{
    width: 1655px; // 4 tools in row
    margin-bottom: 15px;
    @media (width <= 1655px){ // first time toolCards = 3 not 4 in row
        width: 1090px;        
    }
    @media (width <= 1234px){ // first time toolCards = 2 not 3 in row
        width: 560px;        
    }
    @media (width <= 813px){ // first time toolCards = 1 not 2 in row
        width: 320px; 
        flex-wrap: wrap !important;      
    }
}

.tool{ // 1 of 4 "tools"
    img{
        border-radius: $borderRadiusLarge;
    }
    @media (width<=$mobile){
        width: 330px;
    }
}