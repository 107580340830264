@import "../../scss/vars.scss";

.faq{
    padding: 15px;
    border-radius: $borderRadiusSmall;
    border-bottom: 1px solid $gray;
    width: 700px; 
    @media (width<=$mobile){
        width: 90vw;
    }
}