@import "../../scss/vars.scss";

.header__btn {
    box-shadow: inset 0 0 0 0 darken($brand, 10%);
    color: darken($brand, 10%);
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
  }
  .header__btn:hover {
    color: #fff;
    box-shadow: inset 200px 0 0 0 darken($brand, 10%);
  }