// ! hiddenInput
// input is centered to (.por: position: relative)
// parent MUST have .por className
// to show browser native "required" hint, if input value is ""/false/...
.hiddenInput{
	position: absolute;
	transform: translate(-50%,-100%);
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
}
// ? hiddenInput
// ! OTHER
.op0{
	opacity: 0;
}
.op01{
	opacity: 0.1;
}
.op1{
	opacity: 1;
}
.oh{
	overflow: hidden;
}
.oxh{
	overflow-x: hidden;
}
.toe{
	text-overflow: ellipsis;
}
.wsn{
	white-space: nowrap;
}
.br{
	border: 1px solid red !important;
}
.bb{
	border: 1px solid blue !important;
}
.bd{
	border: 2px dashed $gray;
	&_brand{
		border: 2px dashed $brand !important;
	}
}
.oa{
	overflow: auto;
}
.pen{
	pointer-events: none;
}
.lsn{
	list-style: none;
}
.tr180{
	transform: rotate(180deg) !important;
}
.tr90{
	transform: rotate(90deg);
}
.tr-90{
	transform: rotate(-90deg);
}
.ofCover{
	object-fit: cover;
}
.ofContain{
	object-fit: contain;
}
.changeOrder21{
	div:nth-child(2){
		order: 1;
	}
	div:nth-child(1){
		order: 2;
	}
}
.transition05 {
    transition: all 0.5s ease;
}
.transition15 {
    transition: all 1.5s ease;
}
// gen scale
@for $i from 1 through 100 {
	.scale#{$i} {
		transform: scale(#{$i}/100) !important; // ???
	}
}
.bsh{
	box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
	&:hover{
		box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.2);
	}
}
.bsh05{
	box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.5);
	&:hover{
		box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.6);
	}
}

// ! glass
.glass{
	background: rgba(255, 255, 255, 0.8) !important;
	backdrop-filter: blur(3px) !important;
}

.dark{
	.glass{
		background: rgba(40, 44, 52, 0.8) !important; // $dark300 with alpha chanel 0.8
	}
}
// ? glass


// COMMENTED TO SAVE MEMORY
// gen rotate
// @for $i from 0 through 360 {
// 	.rotate#{$i} {
// 		transform: rotate(#{$i}deg);
// 	}
// }