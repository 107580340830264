@import "../../scss/vars.scss";

@mixin transition {
    transition: all 0.3s ease;
}

.transition {
    @include transition;
}

// UNCOMMENT TO WORK
// .expTitleActive{
//     div:nth-child(1){
//         @include transition;
//         animation: expDiv1Active 1s forwards;
//     }
//     div:nth-child(2){
//         @include transition;
//         animation: expDiv2Active 1s forwards;
//     }
// }

@keyframes expDiv1Active {
    from{
        transform: translateX(0px);
    }
    to{
        transform: translateX(50px);
    }
}
@keyframes expDiv2Active {
    from{
        transform: translate(0px, 0px);
    }
    to{
        transform: translate(-68px, 20px);
    }
}
// !
// UNCOMMENT TO WORK
// .expTitleNotActive{
//     div:nth-child(1){
//         @include transition;
//         animation: expDiv1NotActive 1s forwards;
//     }
//     div:nth-child(2){
//         @include transition;
//         animation: expDiv2NotActive 1s forwards;
//     }
// }

@keyframes expDiv1NotActive {
    from{
        transform: translateX(50px);
    }
    to{
        transform: translateX(0px);
    }
}
@keyframes expDiv2NotActive {
    from{
        transform: translate(-68px, 20px);
    }
    to{
        transform: translate(0px, 0px);
    }
}

// ! rotatingBorder
.rotatingBorder{
    border: 2px $black solid;
    border-color: $black transparent transparent transparent;
    animation: rotatingBorder 2.5s infinite ease-in-out;
}

@keyframes rotatingBorder {
    0%{
        opacity: 0;
        transform: rotate(75deg);
    }
    50%{
        opacity: 0.15;
    }
    100%{
        opacity: 0;
        transform: rotate(360deg);
    }
}

// ! mobile
.exps{
    padding-left: 50px;
    @media (width<=$tablet) {
        padding-left: 0;
    }
}

.exp{
@media (width<=$tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    left: 0 !important;
    text-align: center;
    padding: 15px;
    img{
        margin: 0 auto;
    }
    .rotatingBorder{ // hide company icons that show site preview img
        display: none;
    }
}
}

.experienceAndEducation{
    height: auto;
    gap: 80px !important;
    // each Exp Comp is moved + 100px right, so we need to compensate it
    @media (width <= calc($tablet + 300px)) {
        flex-direction: column;        
    }
}