@import "../../scss/vars.scss";

// mui snackbar
.MuiSnackbar-root{
	bottom: 50px !important;
	@media (max-width:$mobile) {
		bottom: 70px !important;
	}
	a{
		color: $white;
	}
}