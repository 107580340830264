@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskerville:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');


.font2{
	font-family: Baskerville;
}
.font3{
	font-family: Londrina Solid;
}
.font4{
	font-family: Londrina Outline;
}

// gen fw
@for $i from 1 through 9 {
	.fw#{$i}00 {
	  font-weight: #{$i}00;
	}
 }
// gen fz
@for $i from 1 through 100 {
	.fz#{$i} {
	  font-size: #{$i}px;
	}
 }
 // gen letter spacing
@for $i from 1 through 10 {
	.ls#{$i} {
	  letter-spacing: #{$i}px;
	}
 }
  // gen line height
@for $i from 1 through 100 {
	.lh#{$i} {
	  line-height: #{$i}px;
	}
 }
 // ! title
 // TODO clamp(min,def,max) ?
.title{
	font-size: 26px;
}
.title2{
	font-size: 20px;
}
.title3{
	font-size: 16px;
}
.title,.title2,.title3{
    font-weight: 500;
}
// ! other
.tac{
	text-align: center;
}
.tal{
	text-align: left;
}
.tar{
	text-align: right;
}
.fsi{
	font-style: italic;
}
.tdu{
	text-decoration: underline;
}
.a_tdu{
	a{
		text-decoration: underline;
	}
}
.tdlt{
	text-decoration: line-through;
}
.tdn{
	text-decoration: none;
}
.ttu{
	text-transform: uppercase;
}
.ts{
	-webkit-text-stroke: 1px $black;
}
.wbba{
	word-break: break-all;
}
.hya{
	hyphens: auto;
}
.wspl{
	white-space: pre-line !important;
}
  // gen lines of text to show
  @for $i from 0 through 10 {
	.textLines#{$i} {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: #{$i}; // number of lines
		-webkit-box-orient: vertical;
	}
 }

 .ust{
	user-select: text !important;
	*:not(img){
		user-select: text !important;
	}
 }