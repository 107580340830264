// ! delAnim
.delAnim{
	animation: delAnim 0.9s forwards;
}
@keyframes delAnim {
	to{
		opacity: 0.5;
		filter:grayscale(100%);
		display: none;
	}
}
// ! cardAnim
.cardAnim{
	opacity: 0;
	scale: 0.9;
	animation: cardAnim 0.9s forwards;
}
@keyframes cardAnim {
	to{
		opacity: 1;
		scale: 1;
	}
}
// ! gen opAnim
@for $i from 0 through 10 {
	.opAnim#{$i}{
		opacity: 0;
		animation: opAnim #{$i}s forwards; // 1s, 2s, ...
	}
 }
@keyframes opAnim {
	to{
		opacity: 1;
	}
}
// ! blurAnim
.blurAnim{
	filter: blur(5px);
	animation: blurAnim 0.5s forwards;
}
@keyframes blurAnim {
	to{
		filter: blur(0px);
	}
}
// ! fadeAway
.fadeAway{
	animation: fadeAway 0.5s forwards;
}
@keyframes fadeAway {
	0%{
		opacity: 1;
		// transform: scale(1);
	}
	100%{
		opacity: 0;
		// transform: scale(0);
		display: none;
	}
}

// glow
@keyframes glow {
	0% {
	  box-shadow: 0 0 10px $brand;
	}
	50% {
	  box-shadow: 0 0 20px $brand, 0 0 30px $brand;
	}
	100% {
	  box-shadow: 0 0 10px $brand;
	}
  }
  

  .glow {
	animation: glow 1.5s infinite;
  }

  .scale01{
	animation: scale01 0.9s forwards;
  }

  @keyframes scale01 {
	0% {
	  opacity: 0;
	  transform: scale(0);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
  }

// ! courseLabelAnimation
.courseLabelAnimationFadeOut{
	animation: courseLabelAnimationFadeOut 0.9s forwards;
}

@keyframes courseLabelAnimationFadeOut {
	0% {
	  opacity: 1;
	}
	100% {
		transform: translate(0,-40px);
		opacity: 0;
	}
}

.courseLabelAnimationFadeIn{
	animation: courseLabelAnimationFadeIn 0.9s forwards;
}

@keyframes courseLabelAnimationFadeIn {
	0% {
	  opacity: 0;
	  transform: translate(0,-40px);
	}
	100% {
		opacity: 1;
	}
}

.courseBuyButtonAnimationFadeIn{
	animation: courseBuyButtonAnimationFadeIn 0.9s forwards;
}

@keyframes courseBuyButtonAnimationFadeIn {
	0% {
	  opacity: 0;
	  transform: translate(0,40px);
	}
	100% {
		opacity: 1;
	}
}

.courseBuyButtonAnimationFadeOut{
	animation: courseBuyButtonAnimationFadeOut 0.9s forwards;
}

@keyframes courseBuyButtonAnimationFadeOut {
	0% {
	  opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translate(0,50px);
	}
}
// ? courseLabelAnimation

// ! header
.headerFadeOut{
	animation: headerFadeOut 0.9s forwards;
}

@keyframes headerFadeOut {
	0% {
	  opacity: 1;
	}
	100% {
		transform: translate(0,-80px);
		opacity: 0;
	}
}

.headerFadeIn{
	animation: headerFadeIn 0.9s forwards;
}

@keyframes headerFadeIn {
	0% {
		transform: translate(0,-80px);
		opacity: 0;
	}
	100% {
		transform: translate(0,0px);
		opacity: 1;
	}
}
// ? header

// ! appLoaderAnimation
.appLoaderAnimation{
	animation: appLoaderAnimation 1.1s infinite;
}

@keyframes appLoaderAnimation {
	0% {
		color: #D19B5B; /* Red */
	  }
	  50% {
		color: orange; /* Blue */
	  }
	  100% {
		color: #D19B5B; /* Red (repeat) */
	  }
}

.appLoaderAnimationFadeOut{
	animation: appLoaderAnimationFadeOut 1.1s forwards;
}

@keyframes appLoaderAnimationFadeOut {
	0% {
		opacity: 1;
	  }
	  100% {
		opacity: 0;
		display: none;
	  }
}
// ? appLoaderAnimation

// ! appearFromRight
.appearFromRight{
	animation: appearFromRight 0.9s forwards;
}

@keyframes appearFromRight {
	0% {
		transform: translate(100%,0) scale(0);
		filter: blur(10px);
		opacity: 0;
	}
	100% {
		transform: translate(0,0) scale(1);
		filter: blur(0px);
		opacity: 1;
	}
}

// ! appearFromTop
// MessageReply
.appearFromTop{
	animation: appearFromTop 0.9s forwards;
}

@keyframes appearFromTop {
	0% {
		transform: translate(0,-100%) scale(0) rotateY(0deg);
		filter: blur(10px);
		opacity: 0;
	}
	100% {
		transform: translate(0,0) scale(1) rotateY(180deg);
		filter: blur(0px);
		opacity: 1;
	}
}