@import "../../scss/vars";

.applyForQuote{
    .title{
        color: $brand !important;
    }
    .bg_black{
        background: $brand !important;
    }
@media (width<=$mobile) {
    // coach name title
    .title{
        font-size: 22px !important;
    }
    // line before coach name title
    .bg_black{
        width: 100px;
    }
}
}

.applyFor{
    @media (width <=$mobile) {
        height: auto;
        margin-bottom: 100px;
    }
}