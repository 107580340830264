@import "vars.scss";

@mixin transition {
    transition: all 0.3s ease;
}

.hoverScale{
    @include transition;
    &:hover{
        transform: scale(1.1);
    }
    &:active{
        transition: all 0.9s ease;
        transform: scale(0.9);
        opacity: 0.5;
    }
}
.hoverBrandColor{
    @include transition;
    &:hover{
        color: $brand !important;
    }
}
.hoverFont500{
    @include transition;
    &:hover{
        font-weight: 500;
    }
}
.hoverFontTop{
    @include transition;
    &:hover{
        position: relative;
        top: -2px;
    }
}
.hoverFont3{
    &:hover{
        font-family: Londrina Outline;
    }
}
.hoverFont4{
    &:hover{
        font-family: Londrina Solid;
    }
}
.hoverMainImg{
    @include transition;
    &:hover{
        border: 5px solid lighten($brand, 10%);
        border-bottom-left-radius: 50%;
        border-top-right-radius: 50%;
        background: lighten($brand, 10%);
    }
}
.hoverMainImg2{
    @include transition;
    &:hover{
        border: 5px solid lighten($brand, 10%);
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background: lighten($brand, 10%);
    }
}
.hoverImgRotateY{
    &:hover{
        img{
            @include transition;
            transform: rotateY(180deg);
        }
    }
}
.scaleImgWidth{
    animation: scaleImgWidth 1s forwards;
}
@keyframes scaleImgWidth {
    from{
        opacity: 0;
        width: 0%;
    }
    to{
        opacity: 1;
        width: 100px;
    }
}

.hoverHeight55{
    @include transition;
    &:hover{
        height: 55px;
    }
}

// gen hoverBlur
@for $i from 0 through 10 {
	.hoverBlur#{$i} {
        @include transition;
        &:hover{
            filter: blur(#{$i}px);
        }
	}
 }