// color
$appBg: #F2F2F2;
$black: #131313;
$white: #fff;
$brand: #D19B5B;
$danger: #858585;
$danger2: tomato;
$info: #3498db;
$orange: orange;
$gray: #777777;
$grayDarkMode: #bfbfbf;
// theme
$dark100: #16181d;
$dark200: #1e2127;
$dark300: #282c34;
// width
$wS: 330px; // 360p mobile -30px padding
$wM: 675px;
$wL: 1100px;
$cardsWidth: 1380px;
// breakpoints
$mobile: 768px;
$tablet: 1280px;
// borders
$borderRadiusLarge: 20px;
$borderRadiusMedium: 10px;
$borderRadiusSmall: 5px;
// other
$fontMain: Montserrat;
$fontSecondary: Roboto;